.header-main {
    position: absolute;
    right: 0px;
    left: auto;
    top: 66px;
    font-weight: 400;
    width: auto;
    margin: 6px 0;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 8%);
    min-width: 150px;
    list-style: none;
    padding: 2px 7px;
    background: #fff;
}