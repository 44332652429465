.NoPageFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 100%;
    text-align: center;
}
.NoPageFound svg {
    width: 250px;
    height: 185px;
}
.NoPageFound h1 {
    color: #ad2125;
    font-weight: 600;
    margin-bottom: 5px;
}
.NoPageFound p {
    font-weight: 500;
    font-size: 18px;
}