.idCard{
    cursor: pointer;
}
.idCard1 {
    height: 300px;
    width: 80%;
}

.modal-body {
    display: flex;
    justify-content: center;
}