.treeview-menu>li>a.active {
    color: #ff5f78;
}

td .dropdown .dropdown-toggle {
    padding: 0;
    line-height: 1;
    width: 24px;
    height: 24px;
}

td .dropdown .dropdown-toggle:after {
    display: none;
}

.active {
    color: #ed1a3b !important;

}


.folder-name h4 {
    margin-bottom: 0;
    margin-top: 5px;
}

.folder-icon-color {
    display: block;
}


.spinnerInButton {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #ffffff);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
    animation: spinner-zp9dbg 1s infinite linear;
    display: inline-block;
}

@keyframes spinner-zp9dbg {
    to {
        transform: rotate(1turn);
    }
}











.icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.nav-tabs {
    border-bottom: none !important;
}


.nav-tabs .nav-link {
    position: relative;
    color: #000;
    padding: 0.5rem 0.5rem;
    border-radius: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 1px solid #d9d9d9;
}

.theme-primary .nav-tabs .nav-link.active {
    position: relative;
    border-bottom-color: #fff !important;
    background-color: #ffff !important;
    color: #ed1a3b !important;
}

.icon-button .save::after {
    color: #22bb33;
}

.icon-button.delete:hover i {
    color: #bb2124;
}

.icons-width {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(100% - 75%);
}

.nav-tabs span {
    font-weight: 700;
    font-size: smaller;
}

@media (max-width:768px) {

    .nav-tabs span {
        font-weight: 700;
        font-size: 10px !important;
        /* padding-right: calc(100% - 30%); */
    }

    .nav-tabs img {
        width: 100% !important;
    }

    .nav-tabs .nav-link {
        padding: 8px 12px !important;
    }

}

.nav-tabs img {
    max-width: 100% !important;
}


.list-container {
    max-width: 100%;
    /* max-height: 200px;  */
    overflow-x: auto;
}

/* Webkit scrollbar styles */
.list-container::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
    height: 5px;
}

/* Track styles */
.list-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Set the background color of the track */
}

/* Handle styles */
.list-container::-webkit-scrollbar-thumb {
    background: #888;
    /* Set the color of the scrollbar handle */
    border-radius: 5px;
    /* Set border radius to make it round */
}

/* Handle on hover styles */
.list-container::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Change color on hover */
}


.some-class:before {
    border: none;
}

.some-class:after {
    content: '';
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    left: 0;
    right: 0;
    top: 100%;
    margin: auto;
    position: absolute;
    display: none;
    /* Initially hide the triangle */
}

.some-class.triangle-visible:after {
    display: block;
    /* Show the triangle when the specific <li> is clicked */
}


.nav>li>a:hover,
.nav>li>a:active,
.nav>li>a:focus {
    color: #172b4c;
    background: #fff !important;
}

.drag-area {
    border: 4px dashed #cdcdcd;
    min-height: 350px;
    width: 45%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    text-align: center;
    padding: 20px;
}

.drag-area.active {
    border: 2px solid #fff;
}

.drag-area .icon {
    font-size: 88px;
    color: #fff;
    line-height: 1;
}

.drag-area .icon i {
    color: #ecacb6;
    height: auto;
    width: auto;
    margin: 0;
    line-height: 1;
}

.drag-area h4 {
    font-size: 24px;
    font-weight: 500;
    color: #172b4c;
    margin: 0;
}

.drag-area span {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    margin: 10px 0 15px 0;
    line-height: 1;
}

.drag-area button {
    padding: 10px 25px;
    border: none;
    outline: none;
    background: #ed1a3b;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}

.drag-area button:hover {
    background: #f8415d;
}

.drag-area img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}


.folder {
    cursor: pointer;
}





.scheduleBox {
    display: block;
    background: #f7f7f7;
    border-radius: 4px;
    transition: .3s;
    color: #172b4c;
    height: 100%;
    padding-bottom: 10px;
    border-radius: 6px;
    overflow: hidden;
}

.scheduleBoxTop {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    text-align: center;
    justify-content: center;
    padding: 10px;
    background: #f3f3f3;
    margin-bottom: 10px;
}

.scheduleBoxTop>div {
    color: #172b4c;
    margin-left: 7px;
}

.scheduleBox a {
    font-weight: 600;
}

.scheduleLabel {
    display: flex;
    justify-content: space-between;
    padding: 3px 21px;
    /* border-bottom: 1px solid #dedede; */
}

.scheduleBox .scheduleLabel:last-child {
    margin-bottom: 0;
}

.scheduleLabel.total a {
    color: #172b4c;
}

.scheduleLabel.overdue a {}

.scheduleLabel.due a {
    color: #e69800;
}

.form-label {
    line-height: 1;
}

.actionBtn {
    padding: 0;
    line-height: 1;
    width: 24px;
    height: 24px;
}


.LoadingOrError {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.LoadingOrError img:not(.loadingImg) {
    width: 100%;
    max-width: 460px;
}

.LoadingOrError img.loadingImg {
    width: 100%;
    max-width: 300px;
}

.PhoneInputCountry select:disabled {
    opacity: 0;
}

.PhoneInput.PhoneInput--disabled {
    background: #e9ecef;
    border: 1px solid rgb(187, 187, 187);
}

:where(.css-dev-only-do-not-override-11xg00t).ant-picker-outlined.ant-picker-disabled,
:where(.css-dev-only-do-not-override-11xg00t).ant-picker-outlined[disabled] {
    color: #212529;
    background-color: rgb(233 236 239);
    border-color: #bbbbbb;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-picker-outlined.ant-picker-disabled:hover:not([disabled]),
:where(.css-dev-only-do-not-override-11xg00t).ant-picker-outlined[disabled]:hover:not([disabled]) {
    background-color: rgb(233 236 239);
    border-color: #bbbbbb;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-picker .ant-picker-input>input[disabled]::placeholder {
    color: #212529 !important;
}

.password_toggle {
    position: relative;
}

.password_toggle button {
    border: 0;
    position: absolute;
    background: transparent;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    z-index: 6;
}

/* .box-header.with-border .box-in button.btn.btn-danger-light {
    display: none;
}

.box-header.with-border .box-in button.btn.btn-danger-light+h4.box-title.me-auto.ms-4 {
    margin-left: 0 !important;
} */