.otpMain {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    position: relative;
    background: var(--bs-white);
}

.otpMain>div {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;

}

.otpbutton {
    border: medium none;
    color: #FFFFFF;
    display: block;
    font: 12px;
    letter-spacing: 1px;
    margin: 30px 0px 0px;
    padding: 8px 15px;
    position: relative;
    border-radius: 5px;
    background: var(--bs-primary);
}
.otp-title {
    color: var(--bs-text-blue);
    font-size: 20px;
    font-weight: 500;
    width: 100%;
}

.we-login-register-otp {
    padding: 40px 50px;
    position: relative;
    width: 80%;
    background: var(--bs-white);
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 25%);
    border-radius: 24px;
}

.we-login-register-otp>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}