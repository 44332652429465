.add-client-save-btn {
    display: flex;
    justify-content: center;
}

.manual-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

.form-control-invalid:not(.PhoneInputInput) {
    border: 1PX solid #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control-invalid.PhoneInputInput {
    border: 1PX solid #dc3545;
}

.form-control-invalid .PhoneInputCountry {
    border-color: #dc3545;
}

.form-control-valid.PhoneInput {
    border-color: #198754;
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.PhoneInput {
    max-width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0;
    border-right: 1px solid #bbbbbb;
    padding: 0px 5px;
    border-radius: 5px 0px 0px 5px;
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountryIcon {
    width: calc(1em* 1.8);
    height: 1.2em;
}

.PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: currentColor;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
}

.PhoneInputInput {
    display: block;
    width: 100%;
    height: auto;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    border-left: 0 !important;
    outline: 0;
    border-radius: 4px;
}

.timeline-fill-box {
    position: relative;
}

.timeline-fill-box img {
    border-radius: 10px;
    border: 4px solid var(--primary_light);
    cursor: pointer;
    transition: .3s;
}

.timeline-fill-box img:hover {
    border: 4px solid var(--bs-danger);
}

.timeline-fill-box svg {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 0 4px rgb(134 134 134 / 20%);
}



/* .PhoneInput>input:valid{
    border-color: #198754;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
} */