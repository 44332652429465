.active {
  font-weight: bold;
}

.sidebar_level {
  padding-right: 15px;
}

.sidebar_level_m {
  margin-bottom: 5px;
}