@charset 'UTF-8';
[class^='wi-'],
[class*=' wi-']
{
    font-family: 'weathericons';
    font-weight: normal;
    font-style: normal;

    position: relative;

    display: inline-block;

    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
         -o-transform: translate(0, 0);
            transform: translate(0, 0); 

    text-rendering: auto;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face
{
    font-family: 'weathericons';
    font-weight: normal;
    font-style: normal; 

    src: url('./weathericons-regular-webfont.eot');
    src: url('./weathericons-regular-webfont.eot?#iefix') format('embedded-opentype'), url('./weathericons-regular-webfont.woff') format('woff'), url('./weathericons-regular-webfont.ttf') format('truetype'), url('./weathericons-regular-webfont.svg#weathericons-regular-webfontRg') format('svg');
}

.wi
{
    font-family: 'weathericons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;

    display: inline-block;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wi-day-cloudy-gusts:before
{
    content: '';
}

.wi-day-cloudy-windy:before
{
    content: '';
}

.wi-day-cloudy:before
{
    content: '';
}

.wi-day-fog:before
{
    content: '';
}

.wi-day-hail:before
{
    content: '';
}

.wi-day-lightning:before
{
    content: '';
}

.wi-day-rain-mix:before
{
    content: '';
}

.wi-day-rain-wind:before
{
    content: '';
}

.wi-day-rain:before
{
    content: '';
}

.wi-day-showers:before
{
    content: '';
}

.wi-day-snow:before
{
    content: '';
}

.wi-day-sprinkle:before
{
    content: '';
}

.wi-day-sunny-overcast:before
{
    content: '';
}

.wi-day-sunny:before
{
    content: '';
}

.wi-day-storm-showers:before
{
    content: '';
}

.wi-day-thunderstorm:before
{
    content: '';
}

.wi-cloudy-gusts:before
{
    content: '';
}

.wi-cloudy-windy:before
{
    content: '';
}

.wi-cloudy:before
{
    content: '';
}

.wi-fog:before
{
    content: '';
}

.wi-hail:before
{
    content: '';
}

.wi-lightning:before
{
    content: '';
}

.wi-rain-mix:before
{
    content: '';
}

.wi-rain-wind:before
{
    content: '';
}

.wi-rain:before
{
    content: '';
}

.wi-showers:before
{
    content: '';
}

.wi-snow:before
{
    content: '';
}

.wi-sprinkle:before
{
    content: '';
}

.wi-storm-showers:before
{
    content: '';
}

.wi-thunderstorm:before
{
    content: '';
}

.wi-windy:before
{
    content: '';
}

.wi-night-alt-cloudy-gusts:before
{
    content: '';
}

.wi-night-alt-cloudy-windy:before
{
    content: '';
}

.wi-night-alt-hail:before
{
    content: '';
}

.wi-night-alt-lightning:before
{
    content: '';
}

.wi-night-alt-rain-mix:before
{
    content: '';
}

.wi-night-alt-rain-wind:before
{
    content: '';
}

.wi-night-alt-rain:before
{
    content: '';
}

.wi-night-alt-showers:before
{
    content: '';
}

.wi-night-alt-snow:before
{
    content: '';
}

.wi-night-alt-sprinkle:before
{
    content: '';
}

.wi-night-alt-storm-showers:before
{
    content: '';
}

.wi-night-alt-thunderstorm:before
{
    content: '';
}

.wi-night-clear:before
{
    content: '';
}

.wi-night-cloudy-gusts:before
{
    content: '';
}

.wi-night-cloudy-windy:before
{
    content: '';
}

.wi-night-cloudy:before
{
    content: '';
}

.wi-night-hail:before
{
    content: '';
}

.wi-night-lightning:before
{
    content: '';
}

.wi-night-rain-mix:before
{
    content: '';
}

.wi-night-rain-wind:before
{
    content: '';
}

.wi-night-rain:before
{
    content: '';
}

.wi-night-showers:before
{
    content: '';
}

.wi-night-snow:before
{
    content: '';
}

.wi-night-sprinkle:before
{
    content: '';
}

.wi-night-storm-showers:before
{
    content: '';
}

.wi-night-thunderstorm:before
{
    content: '';
}

.wi-celsius:before
{
    content: '';
}

.wi-cloud-down:before
{
    content: '';
}

.wi-cloud-refresh:before
{
    content: '';
}

.wi-cloud-up:before
{
    content: '';
}

.wi-cloud:before
{
    content: '';
}

.wi-degrees:before
{
    content: '';
}

.wi-down-left:before
{
    content: '';
}

.wi-down:before
{
    content: '';
}

.wi-fahrenheit:before
{
    content: '';
}

.wi-horizon-alt:before
{
    content: '';
}

.wi-horizon:before
{
    content: '';
}

.wi-left:before
{
    content: '';
}

.wi-lightning:before
{
    content: '';
}

.wi-night-fog:before
{
    content: '';
}

.wi-refresh-alt:before
{
    content: '';
}

.wi-refresh:before
{
    content: '';
}

.wi-right:before
{
    content: '';
}

.wi-sprinkles:before
{
    content: '';
}

.wi-strong-wind:before
{
    content: '';
}

.wi-sunrise:before
{
    content: '';
}

.wi-sunset:before
{
    content: '';
}

.wi-thermometer-exterior:before
{
    content: '';
}

.wi-thermometer-internal:before
{
    content: '';
}

.wi-thermometer:before
{
    content: '';
}

.wi-tornado:before
{
    content: '';
}

.wi-up-right:before
{
    content: '';
}

.wi-up:before
{
    content: '';
}

.wi-wind-west:before
{
    content: '';
}

.wi-wind-south-west:before
{
    content: '';
}

.wi-wind-south-east:before
{
    content: '';
}

.wi-wind-south:before
{
    content: '';
}

.wi-wind-north-west:before
{
    content: '';
}

.wi-wind-north-east:before
{
    content: '';
}

.wi-wind-north:before
{
    content: '';
}

.wi-wind-east:before
{
    content: '';
}

.wi-smoke:before
{
    content: '';
}

.wi-dust:before
{
    content: '';
}

.wi-snow-wind:before
{
    content: '';
}

.wi-day-snow-wind:before
{
    content: '';
}

.wi-night-snow-wind:before
{
    content: '';
}

.wi-night-alt-snow-wind:before
{
    content: '';
}

.wi-day-sleet-storm:before
{
    content: '';
}

.wi-night-sleet-storm:before
{
    content: '';
}

.wi-night-alt-sleet-storm:before
{
    content: '';
}

.wi-day-snow-thunderstorm:before
{
    content: '';
}

.wi-night-snow-thunderstorm:before
{
    content: '';
}

.wi-night-alt-snow-thunderstorm:before
{
    content: '';
}

.wi-solar-eclipse:before
{
    content: '';
}

.wi-lunar-eclipse:before
{
    content: '';
}

.wi-meteor:before
{
    content: '';
}

.wi-hot:before
{
    content: '';
}

.wi-hurricane:before
{
    content: '';
}

.wi-smog:before
{
    content: '';
}

.wi-alien:before
{
    content: '';
}

.wi-snowflake-cold:before
{
    content: '';
}

.wi-stars:before
{
    content: '';
}

.wi-night-partly-cloudy:before
{
    content: '';
}

.wi-umbrella:before
{
    content: '';
}

.wi-day-windy:before
{
    content: '';
}

.wi-night-alt-cloudy:before
{
    content: '';
}

.wi-up-left:before
{
    content: '';
}

.wi-down-right:before
{
    content: '';
}

.wi-time-12:before
{
    content: '';
}

.wi-time-1:before
{
    content: '';
}

.wi-time-2:before
{
    content: '';
}

.wi-time-3:before
{
    content: '';
}

.wi-time-4:before
{
    content: '';
}

.wi-time-5:before
{
    content: '';
}

.wi-time-6:before
{
    content: '';
}

.wi-time-7:before
{
    content: '';
}

.wi-time-8:before
{
    content: '';
}

.wi-time-9:before
{
    content: '';
}

.wi-time-10:before
{
    content: '';
}

.wi-time-11:before
{
    content: '';
}

.wi-day-sleet:before
{
    content: '';
}

.wi-night-sleet:before
{
    content: '';
}

.wi-night-alt-sleet:before
{
    content: '';
}

.wi-sleet:before
{
    content: '';
}

.wi-day-haze:before
{
    content: '';
}

.wi-beafort-0:before
{
    content: '';
}

.wi-beafort-1:before
{
    content: '';
}

.wi-beafort-2:before
{
    content: '';
}

.wi-beafort-3:before
{
    content: '';
}

.wi-beafort-4:before
{
    content: '';
}

.wi-beafort-5:before
{
    content: '';
}

.wi-beafort-6:before
{
    content: '';
}

.wi-beafort-7:before
{
    content: '';
}

.wi-beafort-8:before
{
    content: '';
}

.wi-beafort-9:before
{
    content: '';
}

.wi-beafort-10:before
{
    content: '';
}

.wi-beafort-11:before
{
    content: '';
}

.wi-beafort-12:before
{
    content: '';
}

.wi-wind-default:before
{
    content: '';
}

.wi-wind-default._0-deg
{
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}

.wi-wind-default._15-deg
{
    -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
         -o-transform: rotate(15deg);
            transform: rotate(15deg);
}

.wi-wind-default._30-deg
{
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
         -o-transform: rotate(30deg);
            transform: rotate(30deg);
}

.wi-wind-default._45-deg
{
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.wi-wind-default._60-deg
{
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
         -o-transform: rotate(60deg);
            transform: rotate(60deg);
}

.wi-wind-default._75-deg
{
    -webkit-transform: rotate(75deg);
        -ms-transform: rotate(75deg);
         -o-transform: rotate(75deg);
            transform: rotate(75deg);
}

.wi-wind-default._90-deg
{
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

.wi-wind-default._105-deg
{
    -webkit-transform: rotate(105deg);
        -ms-transform: rotate(105deg);
         -o-transform: rotate(105deg);
            transform: rotate(105deg);
}

.wi-wind-default._120-deg
{
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
         -o-transform: rotate(120deg);
            transform: rotate(120deg);
}

.wi-wind-default._135-deg
{
    -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
         -o-transform: rotate(135deg);
            transform: rotate(135deg);
}

.wi-wind-default._150-deg
{
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
         -o-transform: rotate(150deg);
            transform: rotate(150deg);
}

.wi-wind-default._165-deg
{
    -webkit-transform: rotate(165deg);
        -ms-transform: rotate(165deg);
         -o-transform: rotate(165deg);
            transform: rotate(165deg);
}

.wi-wind-default._180-deg
{
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
}

.wi-wind-default._195-deg
{
    -webkit-transform: rotate(195deg);
        -ms-transform: rotate(195deg);
         -o-transform: rotate(195deg);
            transform: rotate(195deg);
}

.wi-wind-default._210-deg
{
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
         -o-transform: rotate(210deg);
            transform: rotate(210deg);
}

.wi-wind-default._225-deg
{
    -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
         -o-transform: rotate(225deg);
            transform: rotate(225deg);
}

.wi-wind-default._240-deg
{
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
         -o-transform: rotate(240deg);
            transform: rotate(240deg);
}

.wi-wind-default._255-deg
{
    -webkit-transform: rotate(255deg);
        -ms-transform: rotate(255deg);
         -o-transform: rotate(255deg);
            transform: rotate(255deg);
}

.wi-wind-default._270-deg
{
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
         -o-transform: rotate(270deg);
            transform: rotate(270deg);
}

.wi-wind-default._285-deg
{
    -webkit-transform: rotate(295deg);
        -ms-transform: rotate(295deg);
         -o-transform: rotate(295deg);
            transform: rotate(295deg);
}

.wi-wind-default._300-deg
{
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
         -o-transform: rotate(300deg);
            transform: rotate(300deg);
}

.wi-wind-default._315-deg
{
    -webkit-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
         -o-transform: rotate(315deg);
            transform: rotate(315deg);
}

.wi-wind-default._330-deg
{
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
         -o-transform: rotate(330deg);
            transform: rotate(330deg);
}

.wi-wind-default._345-deg
{
    -webkit-transform: rotate(345deg);
        -ms-transform: rotate(345deg);
         -o-transform: rotate(345deg);
            transform: rotate(345deg);
}

.wi-moon-new:before
{
    content: '';
}

.wi-moon-waxing-cresent-1:before
{
    content: '';
}

.wi-moon-waxing-cresent-2:before
{
    content: '';
}

.wi-moon-waxing-cresent-3:before
{
    content: '';
}

.wi-moon-waxing-cresent-4:before
{
    content: '';
}

.wi-moon-waxing-cresent-5:before
{
    content: '';
}

.wi-moon-waxing-cresent-6:before
{
    content: '';
}

.wi-moon-first-quarter:before
{
    content: '';
}

.wi-moon-waxing-gibbous-1:before
{
    content: '';
}

.wi-moon-waxing-gibbous-2:before
{
    content: '';
}

.wi-moon-waxing-gibbous-3:before
{
    content: '';
}

.wi-moon-waxing-gibbous-4:before
{
    content: '';
}

.wi-moon-waxing-gibbous-5:before
{
    content: '';
}

.wi-moon-waxing-gibbous-6:before
{
    content: '';
}

.wi-moon-full:before
{
    content: '';
}

.wi-moon-waning-gibbous-1:before
{
    content: '';
}

.wi-moon-waning-gibbous-2:before
{
    content: '';
}

.wi-moon-waning-gibbous-3:before
{
    content: '';
}

.wi-moon-waning-gibbous-4:before
{
    content: '';
}

.wi-moon-waning-gibbous-5:before
{
    content: '';
}

.wi-moon-waning-gibbous-6:before
{
    content: '';
}

.wi-moon-3rd-quarter:before
{
    content: '';
}

.wi-moon-waning-crescent-1:before
{
    content: '';
}

.wi-moon-waning-crescent-2:before
{
    content: '';
}

.wi-moon-waning-crescent-3:before
{
    content: '';
}

.wi-moon-waning-crescent-4:before
{
    content: '';
}

.wi-moon-waning-crescent-5:before
{
    content: '';
}

.wi-moon-waning-crescent-6:before
{
    content: '';
}
